import { createApp } from 'vue'
import App from './App.vue'
import Home from './Home.vue'
import { createRouter, createWebHistory } from 'vue-router';
import User from './User.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

const routes = [
    { path: '/', component: Home },
    { path: '/user', component: User },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

createApp(App).use(Quasar, quasarUserOptions).use(router).mount('#app')