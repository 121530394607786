import script from "./ConfirmPopup.vue?vue&type=script&setup=true&lang=ts"
export * from "./ConfirmPopup.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSlideItem from 'quasar/src/components/slide-item/QSlideItem.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QSlideItem,QIcon,QItem,QItemSection});
