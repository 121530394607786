import 'quasar/dist/quasar.css'
import '@quasar/extras/material-icons/material-icons.css'
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';

export default {
  config: {
    dark: true,
    brand: {
      primary: '#2b2e41',
      secondary: '#FF728B',
      accent: '#9C27B0',
      dark: '#2b2e41',
      'dark-page': '#1E1E2E',
      positive: '#21BA45',
      negative: '#C10015',
      info: '#31CCEC',
      warning: '#F2C037'
    }
  },
  plugins: {
    Dialog, Notify
  }
}