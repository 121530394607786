export interface User {
  Gems: string
  CashCents: string
  BonusCashCents: string
  Level: string
  XPInLevel: string
  Status: string
  Suspended: boolean
  Email: string
}

export enum Balance {
  Balance_Cash = "Cash 💵",
  Balance_CashBonus = "Bonus Cash 💸",
  Balance_Gems = "Gems 💎",
}

export enum UserReadonlyProperties {
  DisplayName = "Display Name",
  Level = "Level",
  TotalDepositCount = "TotalDepositCount",
  TotalDepositedAmount = "TotalDepositedAmount",
  WithdrawalsSubmitted = "WithdrawalsSubmitted",
  WithdrawalsApproved = "WithdrawalsApproved",
  WithdrawalsRejected = "WithdrawalsRejected",
  TotalWithdrawnAmount = "TotalWithdrawnAmount",
  PayingUser = "Paying User",
  EmailVerificationStatus = "EmailVerificationStatus",
  EditUserLink = "EditUserLink",
  EditContactLink = "EditContactLink", 
  Blacklisted = "Blacklisted"
}

export interface WithdrawalRequest {   
  Cash: number 
}

export interface Contact {
  Email?: string
  Name: string
  Phone: string
}

export interface GetUserOutput {
  User: User,
  Contact: Contact,
  UserStats: any,
  UserGameStats: any,
  Balance: any,
  Transactions: Transaction[]
  Tournaments: Tournament[], 
  WithdrawalRequest?: WithdrawalRequest
  TopOpponents: any[]
}

export interface Transaction { Gems: number, Cash: number, BonusCash: number }
export interface TournamentDefinition { DisplayName: string, TournamentType: string, ParticipantsCount: number }
export interface GameSession { GameSessionArn: string }

export interface Tournament {
  ID: string,
  GameSession: GameSession,
  CreationTimestamp: number,
  TournamentDefinition: TournamentDefinition,
  ClaimPrizeID: string,
  PrizeAmountCash: number,
  PrizeAmountBonusCash: number,
  PrizeAmountGems: number,
  ParticipantsCount: number
  OpponentDisplayName: string
  OpponentID: string
}

export interface UserUpdate {
  Level: string
  XPInLevel: string
  Status: string
  Suspended: boolean
  UserID: string
}
